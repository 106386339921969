// src/App.js
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import LandingPage from "./LandingPage";
import Portfolio from "./Portfolio";
import GlobalStyle from "./GlobalStyle";
import UserContext from "./UserContext";

function App() {
  const user = {
    name: "Christian King",
    profession: "AI Researcher",
  };

  const location = useLocation();
  const transitionSettings = { duration: 0.6 };

  return (
    <UserContext.Provider value={user}>
      <GlobalStyle />

      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <motion.div
                initial={{ y: window.innerHeight, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -window.innerHeight, opacity: 0 }}
                transition={transitionSettings}
                style={{ backgroundColor: "#fff", width: "100vw", height: "100vh" }}
              >
                <LandingPage />
              </motion.div>
            }
          />
          <Route
            path="/portfolio"
            element={
              <motion.div
                initial={{ y: window.innerHeight, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -window.innerHeight, opacity: 0 }}
                transition={transitionSettings}
                style={{
        backgroundColor: "#fff",
        width: "100vw",
        minHeight: "100vh",
        overflowY: "auto",
      }}
              >
                <Portfolio />
              </motion.div>
            }
          />
        </Routes>
      </AnimatePresence>
    </UserContext.Provider>
  );
}

export default App;

