import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyles = createGlobalStyle`
  ${reset}

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@900&display=swap');

  body {
    font-family: 'Roboto', sans-serif, bold;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* crucial for correct width calculations */
    overflow-x: hidden; 
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;

export default GlobalStyles;

