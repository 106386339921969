import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Example data for 10 projects,
// each with 5 image URLs, a PDF link, and a GitHub link
const projects = [
  {
    id: 1,
    title: "Dungeons and Dragons Dice Reader",
    description: "An independently developed Dungeons and Dragons dice reader application that processes video streams to detect and classify dice ranging from 4-sided to 20-sided by utilizing YOLO and Resnet for precise image cropping, segmentation, and classification. Captured, annotated, and augmented 100s of images to construct a diverse and powerful dataset.",
    images: [
      "/YOLOOutput.png",
      "/PipelineOverview.png",
      "/MakeSense.png",
      "/ComplexCNN.png",
      "/accuracy_plot_for_resnet_model.png",
    ],
    pdfLink: "/DiceReader.pdf",
    githubLink: "",
    downloadLink: "",
    slidesLink: "/DiceReaderSlides.pdf",
  },
  {
    id: 2,
    title: "Forward-Forward Learning: Replication and Extension",
    description: "This independent project explores the paper \"The Forward-Forward Algorithm: Some Preliminary Investigations\", which presents the Forward-Forward Algorithm as an alternative to traditional backpropagation. It aims to replicate key aspects of the original work, introduce modifications, and apply the approach to novel use cases.",
    images: [
      "/MLP.png",
      "/network_basic.png",
      "/ffdata.png",
      "/fftitanticnodropout.png",
    ],
    pdfLink: "/ForwardForward.pdf",
    githubLink: "",
    downloadLink: "ForwardForward.ipynb",
    slidesLink: "ForwardForwardSlides.pdf",
  },
  {
    id: 3,
    title: "Aligning Audio Encoder and LLM Via Preference Fine-Tuning",
    description: "Large Language Models (LLMs) have achieved significant advances in recent years, yet one of their persistent challenges is alignment: a model's ability to output responses that are consistent with human values, norms, and expectations. Numerous solutions have been proposed to address this issue, but most require extensive human-annotated data and computationally expensive training. Zhou et al. tackled this challenge by deliberately inducing model hallucinations to generate dispreferred responses, which can then be used for preference alignment training in Vision Large Language Models (VLLMs). Furthermore, Wu et al. demonstrated that LLMs, when paired with speech encoders like OpenAI's Whisper, deliver promising results in speech tasks such as automatic speech recognition (ASR), speech translation (ST), and speech question answering (SQA). In this paper, we combine the baseline framework of the latter with the hallucination generation approach of the former to explore speech-to-text alignment. The results are mixed, demonstrating promising increases in precision and ROUGE score metrics in Stage 2, accompanied by a decline in performance in Stage 3. While a broad range of hyperparameters and methodologies were explored, there's still opportunity for further refinement and experimentation.",
    images: [
      "/modelarchllm.png",
      "/modelllmma.png",
      "/resultsllm.png",
    ],
    pdfLink: "/LLMAlignment.pdf",
    githubLink: "",
    downloadLink: "",
  },
  {
    id: 4,
    title: "AdaptiCritters: Simulating Population Dynamics with Genetic Algorithms",
    description: "In this work we seek to simulate the evolution of an ambiguous species as it lives through generations of events that may increase or decrease the fitness offered by certain schemata. To achieve this, we made substantial modifications to a standard GA to best represent the environment and evolutionary process of a real/life species.",
    images: [
      "/adapticritter.png",
      "/RQ1.png",
      "/RQ2.png",
      "/RQ3.png",
      "/GUI.png",
    ],
    pdfLink: "/adapticritters.pdf",
    githubLink: "https://github.com/JustinMorera/CAP-5512-Final-Project",
    downloadLink: "",
    slidesLink: "adapticritterslides.pdf",
  },
  {
    id: 5,
    title: "Sullivan's Crypt VR: Oculus Quest Dungeon Adventure Game",
    description: "A VR dungeon adventure game featuring procedurally generated, dynamically crafted immersive environments where players explore and battle enemies with a sword and crossbow. I was the sole developer of the procedural generation systems, including map generation and design. The project was made with Unity and the XR Interaction Toolkit. I also served as the project manager, overseeing development and project coordination.",
    images: [
      "/vr0.png",
      "/vr1.png",
      "/vr2.png",
      "/vr3.png",
      "/vr4.png",
    ],
    pdfLink: "VRDoc.pdf",
    slidesLink:"VRSlides.pdf",
    videoLink: "https://youtu.be/v7q9D42YeHk?si=ukMYn_g-cnc-yZxs",
  },
  {
    id: 6,
    title: "Study Buddies: Connect With Peers and Ace the Tests",
    description: "Website and mobile app for connecting with peers and creating study groups. Developed with MERN stack. I was responsible for the creation of the mobile app/React Native portion of the project.",
    images: [
      "/phone.png",
      "/websiteproto.png",
    ],
    githubLink: "https://github.com/JavaNoTea/COP4331-Study-Buddies",
    downloadLink: "",
    slidesLink: "studyslides.pdf",
  },
];

const Portfolio = () => {
  const navigate = useNavigate();

  // We'll store the currently enlarged image in state
  const [enlargedImage, setEnlargedImage] = useState(null);

  // Close the enlarged image by setting state back to null
  const closeEnlargedImage = () => {
    setEnlargedImage(null);
  };

  return (
    <PortfolioContainer>
      <Header>
        <BackButton onClick={() => navigate("/")}>Back</BackButton>
        <Title>My Portfolio (Updated 2/5/2025)</Title>
      </Header>

      <ProjectsWrapper>
        {projects.map((project) => (
          <ProjectBox key={project.id}>
            <ProjectTitle>{project.title}</ProjectTitle>
            <ProjectDescription>{project.description}</ProjectDescription>

            {/* Thumbnail Grid */}
            <ThumbnailGrid>
              {project.images.map((imageUrl, idx) => (
                <Thumbnail
                  key={idx}
                  onClick={() => setEnlargedImage(imageUrl)}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                  title="Click to enlarge"
                />
              ))}
            </ThumbnailGrid>

            {/* Links to PDF and GitHub */}
            <ButtonRow>
  {/* Always show the report button */}
  {project.pdfLink && (
  <ProjectLink
    href={project.pdfLink}
    target="_blank"
    rel="noopener noreferrer"
  >
    Report
  </ProjectLink>)}

  {/* Only show GitHub button if a link exists */}
  {project.githubLink && (
    <ProjectLink
      href={project.githubLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      GitHub
    </ProjectLink>
  )}

  {/* Only show Download button if a download link exists */}
  {project.downloadLink && (
    <ProjectLink
      href={project.downloadLink}
      download
      target="_blank"
      rel="noopener noreferrer"
    >
      Code
    </ProjectLink>
  )}

  {/* Only show Slides button if a slides link exists */}
  {project.slidesLink && (
    <ProjectLink
      href={project.slidesLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      Slides
    </ProjectLink>
  )}
  {project.videoLink && (
    <ProjectLink
      href={project.videoLink}
      target="_blank"
      rel="noopener noreferrer"
    >
      Video
    </ProjectLink>
  )}
</ButtonRow>



          </ProjectBox>
        ))}
      </ProjectsWrapper>

      {/* Enlarged Image Modal */}
      {enlargedImage && (
        <Overlay onClick={closeEnlargedImage}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <CloseButton onClick={closeEnlargedImage}>×</CloseButton>
            <EnlargedImage src={enlargedImage} alt="Enlarged view" />
          </Modal>
        </Overlay>
      )}
    </PortfolioContainer>
  );
};

export default Portfolio;

/* ---- Styled Components ---- */

// Main container: white background, takes full viewport height
const PortfolioContainer = styled.div`
  background-color: #fff;
  min-height: 100vh;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;           /* Use the viewport width */
  overflow-x: hidden;     /* Prevent horizontal scroll */
  overflow-y: auto;
`;



// Header with back button & page title
const Header = styled.header`
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

// Back button
const BackButton = styled.button`
  background: transparent;
  border: 2px solid #333;
  color: #333;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-right: auto; /* push title to the right */
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

// Page title
const Title = styled.h1`
  margin: 0 auto 0 0;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #333;

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

// Wrapper for the list of projects
const ProjectsWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

// Individual project box
const ProjectBox = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 1rem;
  }
  
`;

// Title inside each project box
const ProjectTitle = styled.h2`
  margin: 0 0 0.5rem;
  font-family: "Roboto", sans-serif;
  color: #333;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 1.1rem;
  }
`;

// Description inside each project box
const ProjectDescription = styled.p`
  margin: 0 0 1rem;
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;

  @media (max-width: 600px) {
    font-size: 0.9rem;
  }
`;

/* 
  Thumbnails: 
  - Use auto-fit so they'll wrap into new rows as needed 
  - Each column has a minimum width (e.g., 100px) but can stretch 
  - Centered via justify-items and margin auto
*/
const ThumbnailGrid = styled.div`
  display: grid;
  /* Creates as many columns as possible,
     each at least 100px, but they wrap to a new row if there's not enough space */
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
  justify-items: center; /* Center each thumbnail in its column */
  width: 100%; /* Let it take full container width */
  margin: 0 auto; /* Center the grid if narrower than container */
`;

// Each thumbnail: background-image + hover
const Thumbnail = styled.div`
  width: 100%;
  padding-bottom: 60%; /* aspect ratio trick (e.g., 3:2) */
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;

  &:hover {
    opacity: 0.8;
  }
`;

// Row for "View Report" / "View GitHub" buttons
const ButtonRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;
`;

// Styled link for PDF / GitHub
const ProjectLink = styled.a`
  background: transparent;
  border: 2px solid #333;
  color: #333;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }
`;

/* ---- Modal Styles ---- */

// Overlay covers the entire screen with a semi-transparent background
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* above everything else */

  /* For scrolling if image is tall */
  overflow-y: auto;
`;

// The modal itself
const Modal = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  @media (max-width: 600px) {
    max-width: 95%;
    max-height: 80%;
  }
`;

// Close button in top-right corner
const CloseButton = styled.button`
  background: transparent;
  border: none;
  font-size: 2rem;
  line-height: 1;
  color: #333;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  &:hover {
    color: #666;
  }
`;

// The enlarged image within the modal
const EnlargedImage = styled.img`
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 4px;
`;

